.root {
    padding: 9px;
    flex-grow: 1;

    display: grid;
    grid-template-areas: "list details";
    grid-template-columns: 500px 1fr;
    gap: 15px;

    overflow-y: auto;
}

.headed {
    background: white;
    color: black;

    display: grid;
    grid-template-areas: "title"
        "content";
    grid-template-rows: max-content 1fr;
}

.headedTitle {
    grid-area: title;
    padding: 9px;
    color: white;
    background: #707070;
}

.headedChildren {
    grid-area: content;
    overflow-y: auto;
}

.teamList {
    grid-area: list;
}

.teamListItem {
    padding: 9px;
    cursor: pointer;
}

.teamListItemSelected {
    background-color: rgb(204, 0, 0);
    color: white;
}

.teamData {
    height: 100%;
    padding: 9px;
    box-sizing: border-box;

    display: flex;
    gap: 10px;
    flex-direction: column;
}

.teamDataHeader {
    font-size: 18pt;
}

.teamDataGrid {
    flex-grow: 1;
    display: grid;

    grid-template-areas: "claimed remain"
        "members members";
    grid-template-rows: 1fr max-content;
    gap: 15px;

    overflow-y: auto;
}

.teamDataContainer {
    overflow-y: auto;
}

.teamDataClaimed {
    grid-area: claimed;
    border: 1px solid #707070;

    overflow-y: auto;
}

.teamDataRemain {
    grid-area: remain;
    border: 1px solid #707070;

    overflow-y: auto;
}

.teamDataMembers {
    grid-area: members;
    border: 1px solid #707070;
}

.teamDataMembersContent {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    align-items: center;
}

.teamDataMembersContentItem {
    padding: 9px;
}

.interstitial {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
}

.teamProgress {
    display: grid;

    grid-template-areas: "progress progress"
        "claimed remain";
}

.teamProgressBar {
    grid-area: progress;

    display: flex;

    background: rgb(204, 0, 0);
    height: 10px;
}

.teamProgressBarFill {
    background: rgb(0, 200, 0);
}

.teamProgressClaimed {
    grid-area: claimed;

    display: flex;
    flex-direction: column;
}

.teamProgressRemaining {
    grid-area: remain;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.teamProgressBigNum {
    font-size: 15pt;
}


@media (max-width: 900px) {
    .root {
        grid-template-areas: "list"
            "details";
        grid-template-rows: max-content 1fr;
        grid-template-columns: 1fr;
    }

    .teamDataGrid {
        grid-template-areas: "claimed"
            "remain"
            "members";
        grid-template-rows: max-content max-content max-content;
        overflow-y: initial;
    }
}