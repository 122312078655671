@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Montserrat&display=swap);
* {
  font-family: 'Montserrat';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  color: white;
  
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0px;
}

.mainContainer {
  display: flex;
  flex-direction: column;

  max-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;

  flex-grow: 1;
}

.header {
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid rgb(204, 0, 0);
}

.headerButton {
  padding: 9px;
  cursor: pointer;

  white-space: nowrap;
}

.headerButton.selected {
  background: rgb(204, 0, 0);
}

.mainView {
  display: flex;
  flex-grow: 1;

  box-sizing: border-box;
  overflow: auto;

  padding: 20px;
}

.notificationDrawer {
  position: fixed;
  right: 0px;
  bottom: 40px;
  height: 100vh;
  width: 300px;

  pointer-events: none;

  display: flex;
  flex-direction: column-reverse;
}

.notification {
  margin-bottom: 10px;

  display: flex;

  background-color: white;
  color: black;
  border-left: 1px solid #707070;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
}

.notificationSplash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  align-self: stretch;

  width: 100px;
  min-width: 100px;
  flex-basis: 100px;
}

.notificationSplash img {
  width: 40px;
}

.notification p {
  padding: 10px;
}

.leaderboardGridWrapper {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  grid-gap: 10px;
  gap: 10px;

  flex-grow: 4;
}

.leaderboardGrid {
  display: grid;
  grid-template-columns: auto auto 100fr auto;
}

.leaderboardGridItem.placeItem {
  font-family: 'JetBrains Mono';
  color: #707070;
  justify-content: center;
}

.leaderboardTotalStations {
  font-size: 10pt;
}

.sectionHeader {
  background-color: #707070;
  color: white;
  padding: 9px;

  position: -webkit-sticky;

  position: sticky;
  top: 0px;

  display: flex;
  align-items: center;
}

.leaderboardGridItem {
  padding: 9px;
  background-color: white;
  color: black;
  border-top: 1px solid #707070;
  
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ticker {
  display: flex;
  align-items: center;
  justify-content: stretch;

  border-bottom: 1px solid rgb(204, 0, 0);
}

.stationsGridWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;

  flex-grow: 1;
}

.stationsGridInnerWrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  color: #707070;
  background-color: white;
  width: 300px;
  height: 500px;

  margin: 10px;
  box-sizing: border-box;

  overflow-y: auto;
}

.stationsGrid {
  display: grid;
  grid-template-columns: auto 100fr;
  grid-template-areas:
    "header header";
}

.errorContainer {
  display: flex;
  flex-direction: column;
  color: #707070;

  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.playersListWrapper {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  min-height: 300px;

  flex-grow: 1;
  background-color: white;
}

.playersListGrid {
  display: grid;
  grid-template-columns: auto 100fr;
  grid-template-areas:
    "header header";
}

.playersListItem {
  padding: 9px;
  background-color: white;
  color: black;
  border-top: 1px solid #707070;
  
  display: flex;
  align-items: center;
  cursor: pointer;
}

.playersListItem.selected {
  background-color: rgb(204, 0, 0);
  color: white;
}

.playersListItem.itemTitle {
  font-size: 10pt;
  text-transform: uppercase;
  padding-bottom: 3px;
  padding-top: 18px;
  cursor: unset;
}

.playerStatsWrapper {
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  flex-grow: 1;
}

.playerStats {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}

.playerStatsContents {
  flex-grow: 1;
  background-color: white;
  color: black;

  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.playerStatsOverview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playerStatsSectionContents div {
  padding: 10px;
}

.playerStatsSection {
  border: 1px solid #707070;
  flex-basis: 500px;
  flex-grow: 1;

  margin: 10px;
}

.playerStatsNotSelected {
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.recentEventsWrapper {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
}

.recentEventItem {
  background-color: white;
  color: black;

  padding: 9px;
  display: flex;
  align-items: center;
}

marquee {
  flex-grow: 1;
}

.settingsWrapper {
  display: flex;
  flex-direction: column;

  background: white;
  color: black;

  flex-grow: 1;
}

.settingsInnerWrapper {
  display: flex;
  flex-direction: column;

  padding: 10px;
  flex-grow: 1;
}

.headerButtons {
  display: flex;
  flex-grow: 1;

  overflow-x: auto;
}

.postListItem {
  padding: 9px;
  background-color: white;
  color: black;
  border-top: 1px solid #707070;
  
  display: grid;
  align-items: center;
  cursor: pointer;

  grid-template-columns: -webkit-max-content 1fr -webkit-max-content;

  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
    "image title date"
    "image subtitle date";
}

.postSubtitle {
  color: #707070;
}

.postText {
  display: flex;
  flex-direction: column;

  padding: 10px;
  flex-grow: 1;

  background-color: white;
  color: black;
}

.postText p:first-child {
  margin-top: 0px;
}

.postText p:last-child {
  margin-bottom: 0px;
}

@media (max-width: 900px) {
  .mainView {
    flex-direction: column;
  }

  .hspacer {
    display: none;
  }

  .leaderboardGridWrapper {
    flex-grow: unset;
  }
}

.playerStationList {
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  align-items: center;
}

.playerStationListShortcode {
  font-family: 'JetBrains Mono';
  color: #707070;
}

.stationHeaderShortcode {
  font-family: 'JetBrains Mono';
  padding-right: 9px;
}

.teamPlayerPadding {
  padding-left: 24px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Teams_root__oMg7a {
    padding: 9px;
    flex-grow: 1;

    display: grid;
    grid-template-areas: "list details";
    grid-template-columns: 500px 1fr;
    grid-gap: 15px;
    gap: 15px;

    overflow-y: auto;
}

.Teams_headed__3tg4T {
    background: white;
    color: black;

    display: grid;
    grid-template-areas: "title"
        "content";
    grid-template-rows: -webkit-max-content 1fr;
    grid-template-rows: max-content 1fr;
}

.Teams_headedTitle__2hJGf {
    grid-area: title;
    padding: 9px;
    color: white;
    background: #707070;
}

.Teams_headedChildren__wUFuQ {
    grid-area: content;
    overflow-y: auto;
}

.Teams_teamList__3lZkC {
    grid-area: list;
}

.Teams_teamListItem__GIqZy {
    padding: 9px;
    cursor: pointer;
}

.Teams_teamListItemSelected__2vsTO {
    background-color: rgb(204, 0, 0);
    color: white;
}

.Teams_teamData__2BMYA {
    height: 100%;
    padding: 9px;
    box-sizing: border-box;

    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
}

.Teams_teamDataHeader__3H-v3 {
    font-size: 18pt;
}

.Teams_teamDataGrid__1cBcq {
    flex-grow: 1;
    display: grid;

    grid-template-areas: "claimed remain"
        "members members";
    grid-template-rows: 1fr -webkit-max-content;
    grid-template-rows: 1fr max-content;
    grid-gap: 15px;
    gap: 15px;

    overflow-y: auto;
}

.Teams_teamDataContainer__2OVC8 {
    overflow-y: auto;
}

.Teams_teamDataClaimed__3Tnib {
    grid-area: claimed;
    border: 1px solid #707070;

    overflow-y: auto;
}

.Teams_teamDataRemain__3NF3N {
    grid-area: remain;
    border: 1px solid #707070;

    overflow-y: auto;
}

.Teams_teamDataMembers__18OKF {
    grid-area: members;
    border: 1px solid #707070;
}

.Teams_teamDataMembersContent__1U8_U {
    display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content 1fr;
    grid-template-columns: max-content max-content 1fr;
    align-items: center;
}

.Teams_teamDataMembersContentItem__1TcdO {
    padding: 9px;
}

.Teams_interstitial__2T7xC {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 9px;
    gap: 9px;
}

.Teams_teamProgress__RM0fO {
    display: grid;

    grid-template-areas: "progress progress"
        "claimed remain";
}

.Teams_teamProgressBar__1D96X {
    grid-area: progress;

    display: flex;

    background: rgb(204, 0, 0);
    height: 10px;
}

.Teams_teamProgressBarFill__3SlA8 {
    background: rgb(0, 200, 0);
}

.Teams_teamProgressClaimed__15EnQ {
    grid-area: claimed;

    display: flex;
    flex-direction: column;
}

.Teams_teamProgressRemaining__2gF0n {
    grid-area: remain;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.Teams_teamProgressBigNum__1XDPQ {
    font-size: 15pt;
}


@media (max-width: 900px) {
    .Teams_root__oMg7a {
        grid-template-areas: "list"
            "details";
        grid-template-rows: -webkit-max-content 1fr;
        grid-template-rows: max-content 1fr;
        grid-template-columns: 1fr;
    }

    .Teams_teamDataGrid__1cBcq {
        grid-template-areas: "claimed"
            "remain"
            "members";
        grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
        grid-template-rows: max-content max-content max-content;
        overflow-y: initial;
    }
}
